<template>

  <body>
    <!-- preloader -->
    <Loader> </Loader>
    <!-- preloader -->

    <div class="frame">
      <div class="site-wrapper overflow-hidden">

        <!-- Header Section -->
        <Front-Header> </Front-Header>


        <!-- Main Start-->

        <section class="contact-us-banner hero_section height-half text-center">
          <img :src="pages.image" style="position: absolute;" :alt="pages.title">
          <div class="container">
            <h1 data-aos="fade-right" data-aos-duration="1200" class="aos-init aos-animate"><span>{{ pages.title
            }}</span></h1>
            <p data-aos="fade-left" data-aos-duration="1200" data-aos-delay="200" class="aos-init aos-animate">{{
                pages.short_description
            }}</p>
          </div>
        </section>


        <!-- faq -->
        <section class="faq-section">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="faq-title text-center pb-3">
                  <h2>{{ pages.title }}</h2>
                </div>
              </div>
              <div class="col-md-12">
                <div class="accordion" role="tablist">
                  <div class="faq" id="accordion" v-for="faq in faqs" :key="faq.id">
                    <div class="card">
                      <div class="card-header" block v-b-toggle="['accordion-' + faq.id]" variant="info">
                        <div class="mb-0">
                          <h5 class="faq-title">
                            <span class="badge">{{ faq.id }}</span> {{ faq.question }}
                          </h5>
                        </div>
                      </div>
                      <b-collapse :id="'accordion-' + faq.id" accordion="my-accordion" role="tabpanel">
                        <div>
                          <div class="card-body">
                            <p>
                              {{ faq.answer }}
                            </p>
                          </div>
                        </div>
                      </b-collapse>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- Main End-->

        <!-- Footer Section -->
        <Front-Footer> </Front-Footer>

      </div>
    </div>

  </body>
</template>

<script>

import Header from './Front-Header';
import Footer from './Front-Footer';
import Loader from './Loader';
import VueMeta from 'vue-meta';
import Vue from 'vue';
Vue.use(VueMeta)
import { db } from '@/main'

import {
  BCard,
  BCardHeader,
  BCollapse,
  BCardText,
  BCardBody,
  BButton,
  VBToggle,
} from 'bootstrap-vue'


export default {
  components: {
    'Front-Header': Header,
    'Front-Footer': Footer,
    'Loader': Loader,
    BCard,
    BCardHeader,
    BCollapse,
    BCardText,
    BCardBody,
    BButton,
  },
  data() {
    return {
      faqs: [],
      pages: {},
      meta_title: '',
      meta_description: '',
      meta_keywords: '',
      text: `
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
          richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
          brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
          tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
          assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
          wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
          vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic
          synth nesciunt you probably haven't heard of them accusamus labore VHS.`
    }
  },
  directives: {
    'b-toggle': VBToggle,
  },
  metaInfo() {
    //alert();
    return {
      title: this.meta_title,
      keywords: this.meta_keywords,
      meta: [
        { name: 'description', content: this.meta_description },
      ]
    }
  },
  created: function () {
    this.fetchItems();
    this.fetchPages();
  },

  methods: {

    fetchItems() {
      var i = 1;

      db.collection("faqs")
        .get()
        .then((querySnapshot) => {
          console.log('hhhh');
          querySnapshot.forEach((doc) => {

            console.log('ggggggg');

            console.log(doc.id, " => ", doc.data());

            this.faqs.push({
              id: i,
              question: doc.data().question,
              answer: doc.data().answer,
              created: doc.data().created,
            });

            i++;


          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });


    },
    fetchPages() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      db.collection("pages").doc('Bce6vXMp00IqhGL00Nfv')
        .get()
        .then((doc) => {
          console.log('hhhh88');
          console.log(doc.id, " => ", doc.data());
          this.pages = doc.data();
          this.meta_title = doc.data().meta_title;
          this.meta_description = doc.data().meta_description;
          this.meta_keywords = doc.data.meta_keywords;

        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });


    }
  }
}


</script>




